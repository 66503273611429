import React from 'react'
import { motion } from 'framer-motion';
import { fadeInFromBottom, fadeOut } from '../Utils/Variants';

const Card = ({ icon, title, description }) => {
    return (
        <motion.div
            variants={fadeInFromBottom(0.2)} // You can adjust the delay if needed
            initial="hidden" // Initial state
            whileInView="show" // Trigger when in view
            viewport={{ once: false, amount: 0.5 }} className="border bg-[#06103C] p-8 flex flex-col gap-[15px] hover:bg-gradient-to-b from-[#EDF9FF50] to-[#34E3FF50] transition-all duration-1000 ease-in">
            <div className='border-[2px] p-[50px] rounded-full w-max'>
                {icon}
            </div>
            <h3 className="text-white font-bold mb-2 text-[25px]">{title}</h3>
            <p className="text-[17px] font-medium">{description}</p>
        </motion.div>
    );
};

const HowItWork = () => {
    return (
        <div className='w-full flex flex-col justify-center py-[50px] max-[780px]:py-[20px] gap-[20px]'>
            <motion.div
                variants={fadeOut(0.2)} // You can adjust the delay if needed
                initial="hidden" // Initial state
                whileInView="show" // Trigger when in view
                viewport={{ once: false, amount: 0.5 }} className='w-[40%] max-[720px]:w-full flex flex-col gap-[10px]'>
                <h1 className='font-extrabold text-[30px] max-[720px]:text-[25px]'>How it work</h1>
                <p className='text-[15px] max-[720px]:text-[13px]'>Dramatically supply transparent backward deliverables before award comp internal or "organic" sources.</p>
            </motion.div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl w-full">
                <Card
                    icon={<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M26.7888 12.9414L16.9947 22.7356L12.7363 18.4772" stroke="white" strokeWidth="2.555" strokeLinecap="round" strokeLinejoin="round" />
                        <rect x="1.66422" y="1.44351" width="34.9183" height="34.9183" rx="5.53583" stroke="white" strokeWidth="2.555" />
                    </svg>
                    }
                    title="Create Account"
                    description="Interactively provide access to world-class materials for catalysts."
                />
                <Card
                    icon={<svg width="38" height="38" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.36523 4.13153V17.9285C6.36523 18.5384 6.6075 19.1233 7.03874 19.5545C7.46998 19.9857 8.05487 20.228 8.66473 20.228H17.8627C18.4726 20.228 19.0575 19.9857 19.4887 19.5545C19.92 19.1233 20.1622 18.5384 20.1622 17.9285V7.85901C20.1622 7.55267 20.1009 7.24943 19.9821 6.96709C19.8632 6.68475 19.6891 6.429 19.4701 6.21487L15.6587 2.48739C15.2291 2.06734 14.6521 1.83211 14.0513 1.83203H8.66473C8.05487 1.83203 7.46998 2.0743 7.03874 2.50554C6.6075 2.93678 6.36523 3.52166 6.36523 4.13153Z" stroke="white" strokeWidth="2.2995" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M15.5626 20.2283V22.5278C15.5626 23.1377 15.3203 23.7225 14.8891 24.1538C14.4579 24.585 13.873 24.8273 13.2631 24.8273H4.06512C3.45526 24.8273 2.87037 24.585 2.43913 24.1538C2.00789 23.7225 1.76563 23.1377 1.76562 22.5278V9.88055C1.76562 9.27069 2.00789 8.6858 2.43913 8.25456C2.87037 7.82332 3.45526 7.58105 4.06512 7.58105H6.36462" stroke="white" strokeWidth="2.2995" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    }
                    title="Read Rules"
                    description="Interactively provide access to world-class materials for catalysts."
                />
                <Card
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24" fill="none" stroke="#f5f5f5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                        <rect x="3" y="3" width="18" height="18" rx="4" ry="4" stroke="#f5f5f5" fill="none" />
                        <circle cx="8" cy="12" r="1" fill="#f5f5f5" />
                        <circle cx="12" cy="12" r="1" fill="#f5f5f5" />
                        <circle cx="16" cy="12" r="1" fill="#f5f5f5" />
                    </svg>
                    }
                    title="Ask Problems"
                    description="Interactively provide access to world-class materials for catalysts."
                />
                <Card
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24" fill="none" stroke="#f5f5f5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M12 2L3 6v6c0 5.25 3.99 9.14 9 10 5.01-.86 9-4.75 9-10V6l-9-4z" stroke="#f5f5f5" fill="none" />
                        <path d="M9 12l2 2 4-4" stroke="#f5f5f5" fill="none" />
                    </svg>
                    }
                    title="Get Solutions"
                    description="Interactively provide access to world-class materials for catalysts."
                />
                <Card
                    icon={<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" class="feather feather-file-minus"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="9" y1="15" x2="15" y2="15"></line></svg>
                    }
                    title="Well Document"
                    description="Interactively provide access to world-class materials for catalysts."
                />
            </div>
        </div>
    )
}

export default HowItWork