import React from 'react'
import bgImage2 from '../../images/hero.png'
import Button from '../Utils/Button'
import { motion } from 'framer-motion';
import { fadeOut } from '../Utils/Variants';
import { Link } from 'react-router-dom';
const HaveAProject = () => {
    return (
        <div className='h-[400px] w-full bg-cover flex flex-col gap-[20px] items-center justify-center' style={{ backgroundImage: `url('${bgImage2}')` }}>
            <motion.div
                variants={fadeOut(0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.5 }} className='font-extrabold text-[45px] text-center uppercase max-[400px]:text-[25px] max-[900px]:text-[35px]'>have a project in mind? lets’s get to work .
            </motion.div>
            <motion.div
                variants={fadeOut(0.4)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: false, amount: 0.5 }}>
                <Link to='/contactus'><Button radius='8px' paddingX='25px' paddingY='11px' content='Contact us' icon={false} /></Link>
            </motion.div>
        </div>
    )
}

export default HaveAProject