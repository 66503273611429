import React, { useState } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

function NumberAnimation({ n, icon }) {
  const [counterOn, setCounterOn] = useState(false)
  return (
    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
      <h2 className="text-[45px] font-extrabold text-center">
        {counterOn &&
          <>
            <CountUp start={0} end={n} duration={3} delay={0} /> <span>{icon}</span>
          </>
        }
      </h2>
    </ScrollTrigger>
  );
}

export default NumberAnimation;
