import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Home from './components/home/Home';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Portfolio from './components/portfolio/Portfolio';
import Blog from './components/blog/Blog';
import ContactUs from './components/contactUs/ContactUs';
import ServicesPage from './components/servicesPage/ServicesPage';
const App = () => {
  const location = useLocation();
  const getGradientForPage = () => {
    switch (location.pathname) {
      case '/':
        return 'bg-gradient-to-b from-[#050D36] to-[#050D36]';
      case '/services':
        return 'bg-gradient-to-b from-[#050D36] to-[#050D36]';
      case '/portfolio':
        return 'bg-gradient-to-b from-[#3A299F] via-[#05119D] to-[#2351F5]';
      case '/blog':
        return 'bg-gradient-to-b from-[#3165CB] to-[#030115]';
      case '/contactus':
        return 'bg-gradient-to-b from-[#050D36] to-[#050D36]';
      default:
        return 'bg-gradient-to-b from-[#39289F] to-[#422F9C]';
    }
  };

  return (
    <div className={`bg-gradient-to-b from-[#050D36] to-[#050D36] font-bodyFont text-white w-full`}>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<ServicesPage />} />
        {/* <Route path="/portfolio" element={<Portfolio />} /> */}
        {/* <Route path="/blog" element={<Blog />} /> */}
        <Route path="/contactus" element={<ContactUs />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;