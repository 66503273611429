import React, { useEffect } from 'react'
import image from '../../images/Huge Global.png'
import ContactUsForm from './ContactUsForm'
import StayInLoop from '../common/StayInLoop'
import './ContactUs.css'

const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='py-[50px]'>
            <div className='text-white w-[80%] mx-auto gap-[50px] flex flex-col relative pb-[20px]'>
                <div className='w-full flex items-center justify-center gap-[20px] flex-col'>
                    <div className='font-medium text-[35px]'>“Get in Touch with Us”</div>
                    <div>We’re here to answer your questions and provide the information you need. Whether you want to learn more about our services, have a partnership opportunity, or simply want to stay connected, we’d love to hear from you.
                    </div>
                </div>
                <div className='w-full h-[540px] contact_us_map '><img className='w-full h-full object-contain' src={image} /></div>
                <div className='flex justify-between countact_us_below_map'>
                    <div className='w-[32.5%] flex flex-col gap-[20px] border-r countact_us_below_map_single'>
                        <p>Follow us</p>
                        <div className='flex gap-[15px]'>
                            <div className='bg-black w-[34px] h-[34px] rounded-full flex items-center justify-center'>
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_187_2311)">
                                        <path d="M7.99875 1.9925H9.09425V0.0845C8.90525 0.0585 8.25525 0 7.49825 0C5.91875 0 4.83675 0.9935 4.83675 2.8195V4.5H3.09375V6.633H4.83675V12H6.97375V6.6335H8.64625L8.91175 4.5005H6.97325V3.031C6.97375 2.4145 7.13975 1.9925 7.99875 1.9925Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_187_2311">
                                            <rect width="12" height="12" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className='bg-black w-[34px] h-[34px] rounded-full flex items-center justify-center'>
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_187_2316)">
                                        <path d="M9.481 0H3.51898C1.57858 0 0 1.57858 0 3.51898V9.4811C0 11.4214 1.57858 13 3.51898 13H9.4811C11.4214 13 13 11.4214 13 9.4811V3.51898C13 1.57858 11.4214 0 9.481 0V0ZM12.2379 9.4811C12.2379 11.0012 11.0012 12.2379 9.481 12.2379H3.51898C1.99881 12.2379 0.762114 11.0012 0.762114 9.4811V3.51898C0.762114 1.99881 1.99881 0.762114 3.51898 0.762114H9.4811C11.0012 0.762114 12.2379 1.99881 12.2379 3.51898V9.4811Z" fill="white" />
                                        <path d="M6.49989 2.94543C4.53986 2.94543 2.94531 4.53998 2.94531 6.50002C2.94531 8.46005 4.53986 10.0546 6.49989 10.0546C8.45993 10.0546 10.0545 8.46005 10.0545 6.50002C10.0545 4.53998 8.45993 2.94543 6.49989 2.94543ZM6.49989 9.29248C4.96019 9.29248 3.70743 8.03982 3.70743 6.50002C3.70743 4.96032 4.96019 3.70755 6.49989 3.70755C8.03969 3.70755 9.29236 4.96032 9.29236 6.50002C9.29236 8.03982 8.03969 9.29248 6.49989 9.29248Z" fill="white" />
                                        <path d="M10.1392 1.68298C9.55998 1.68298 9.08887 2.1542 9.08887 2.73332C9.08887 3.31254 9.55998 3.78376 10.1392 3.78376C10.7184 3.78376 11.1896 3.31254 11.1896 2.73332C11.1896 2.1541 10.7184 1.68298 10.1392 1.68298ZM10.1392 3.02154C9.98031 3.02154 9.85098 2.89221 9.85098 2.73332C9.85098 2.57433 9.98031 2.4451 10.1392 2.4451C10.2982 2.4451 10.4275 2.57433 10.4275 2.73332C10.4275 2.89221 10.2982 3.02154 10.1392 3.02154Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_187_2316">
                                            <rect width="13" height="13" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className='bg-black w-[34px] h-[34px] rounded-full flex items-center justify-center'>
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_187_2323)">
                                        <path d="M13 2.46919C12.5166 2.68125 12.0014 2.82181 11.4644 2.89006C12.0169 2.56019 12.4386 2.04181 12.6368 1.417C12.1217 1.72412 11.5529 1.94106 10.9468 2.06213C10.4577 1.54131 9.76056 1.21875 9.00006 1.21875C7.52456 1.21875 6.33669 2.41638 6.33669 3.88456C6.33669 4.09581 6.35456 4.29894 6.39844 4.49231C4.18275 4.38425 2.22219 3.32231 0.905125 1.70463C0.675187 2.10356 0.540313 2.56019 0.540313 3.05175C0.540313 3.97475 1.01562 4.79294 1.72413 5.26662C1.29594 5.2585 0.875875 5.13419 0.52 4.93837C0.52 4.9465 0.52 4.95706 0.52 4.96762C0.52 6.26275 1.44381 7.3385 2.65525 7.58631C2.43831 7.64563 2.20187 7.67406 1.9565 7.67406C1.78587 7.67406 1.61362 7.66431 1.45194 7.62856C1.79725 8.684 2.77713 9.45994 3.94225 9.48512C3.0355 10.1944 1.88419 10.6218 0.637812 10.6218C0.41925 10.6218 0.209625 10.6121 0 10.5852C1.18056 11.3466 2.57969 11.7812 4.0885 11.7812C8.99275 11.7812 11.674 7.71875 11.674 4.19737C11.674 4.07956 11.6699 3.96581 11.6643 3.85287C12.1932 3.4775 12.6376 3.00869 13 2.46919Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_187_2323">
                                            <rect width="13" height="13" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className='bg-black w-[34px] h-[34px] rounded-full flex items-center justify-center'>
                                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_187_2330)">
                                        <path d="M10.9971 11V10.9996H10.9998V6.96531C10.9998 4.99173 10.575 3.47144 8.26771 3.47144C7.15854 3.47144 6.41421 4.0801 6.11033 4.65714H6.07825V3.65569H3.89062V10.9996H6.16854V7.36314C6.16854 6.40569 6.35004 5.47985 7.53575 5.47985C8.70404 5.47985 8.72146 6.57252 8.72146 7.42456V11H10.9971Z" fill="white" />
                                        <path d="M0.181641 3.65613H2.46231V11H0.181641V3.65613Z" fill="white" />
                                        <path d="M1.32092 0C0.591708 0 0 0.591708 0 1.32092C0 2.05013 0.591708 2.65421 1.32092 2.65421C2.05013 2.65421 2.64183 2.05013 2.64183 1.32092C2.64138 0.591708 2.04967 0 1.32092 0V0Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_187_2330">
                                            <rect width="11" height="11" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className='w-[32.5%] flex items-center justify-center gap-[15px] border-r countact_us_below_map_single'>
                        <div>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_187_2298)">
                                    <path d="M10.0757 12.3489C11.1038 11.3208 11.1038 9.64807 10.0757 8.61999L6.46006 5.00434C5.90699 4.45122 5.14029 4.17096 4.3573 4.23542C3.58926 4.29864 2.89457 4.69016 2.45143 5.3095C2.35943 5.43805 2.27061 5.56848 2.18359 5.69997L9.45408 12.9705L10.0757 12.3489Z" fill="white" />
                                    <path d="M24.9955 23.5394L21.3798 19.9237C20.3519 18.8958 18.679 18.8956 17.6509 19.9237L17.0293 20.5454L24.3001 27.8161C24.4316 27.7291 24.5618 27.6399 24.6903 27.548C25.3096 27.1049 25.7011 26.4102 25.7644 25.6421C25.8289 24.859 25.5486 24.0926 24.9955 23.5394Z" fill="white" />
                                    <path d="M14.5437 21.939C13.8394 21.939 13.1773 21.6648 12.6793 21.1668L8.83317 17.3207C8.33518 16.8227 8.06084 16.1605 8.06084 15.4562C8.06084 15.0561 8.14961 14.6698 8.31731 14.3193L1.28594 7.28784C0.296879 9.36458 -0.140816 11.6913 0.0397697 14.0267C0.28311 17.1738 1.64026 20.1301 3.86131 22.3511L7.64893 26.1386C9.86987 28.3596 12.8262 29.7168 15.9733 29.9602C16.3172 29.9868 16.6608 30 17.0035 30C18.9884 30 20.9412 29.5575 22.7121 28.7141L15.6806 21.6826C15.3302 21.8503 14.9438 21.939 14.5437 21.939Z" fill="white" />
                                    <path d="M17.6953 0C17.2099 0 16.8164 0.393516 16.8164 0.878906C16.8164 1.3643 17.2099 1.75781 17.6953 1.75781C23.5109 1.75781 28.2422 6.48914 28.2422 12.3047C28.2422 12.7901 28.6357 13.1836 29.1211 13.1836C29.6065 13.1836 30 12.7901 30 12.3047C30 5.51988 24.4801 0 17.6953 0Z" fill="white" />
                                    <path d="M17.6953 12.3039C17.6953 12.3039 17.6953 12.3042 17.6953 12.3047C17.6953 12.7901 18.0888 13.1836 18.5742 13.1836C19.0596 13.1836 19.4531 12.7901 19.4531 12.3047C19.4531 11.3354 18.6646 10.5469 17.6953 10.5469C17.2099 10.5469 16.8164 10.94 16.8164 11.4254C16.8164 11.9108 17.2099 12.3039 17.6953 12.3039Z" fill="white" />
                                    <path d="M17.6953 8.78906C19.6338 8.78906 21.2109 10.3662 21.2109 12.3047C21.2109 12.7901 21.6045 13.1836 22.0898 13.1836C22.5752 13.1836 22.9688 12.7901 22.9688 12.3047C22.9688 9.39691 20.6031 7.03125 17.6953 7.03125C17.2099 7.03125 16.8164 7.42477 16.8164 7.91016C16.8164 8.39555 17.2099 8.78906 17.6953 8.78906Z" fill="white" />
                                    <path d="M17.6953 3.51562C17.2099 3.51562 16.8164 3.90914 16.8164 4.39453C16.8164 4.87992 17.2099 5.27344 17.6953 5.27344C21.5723 5.27344 24.7266 8.42766 24.7266 12.3047C24.7266 12.7901 25.1201 13.1836 25.6055 13.1836C26.0909 13.1836 26.4844 12.7901 26.4844 12.3047C26.4844 7.4584 22.5416 3.51562 17.6953 3.51562Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_187_2298">
                                        <rect width="30" height="30" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className='text-[20px] font-light'>+91 9425442287</div>
                    </div>
                    <div className='w-[32.5%] flex items-center justify-center gap-[15px] countact_us_below_map_single'>
                        <div>
                            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.254 0.89209C9.04313 0.89209 4.80371 5.13151 4.80371 10.3423C4.80371 16.8918 14.2632 28.0796 14.2632 28.0796C14.2632 28.0796 23.7042 16.5697 23.7042 10.3423C23.7042 5.13151 19.4649 0.89209 14.254 0.89209ZM17.1053 13.1094C16.3191 13.8954 15.2866 14.2885 14.254 14.2885C13.2215 14.2885 12.1887 13.8954 11.4028 13.1094C9.83051 11.5373 9.83051 8.97914 11.4028 7.40687C12.1641 6.64521 13.1768 6.22571 14.254 6.22571C15.3311 6.22571 16.3436 6.64537 17.1053 7.40687C18.6776 8.97914 18.6776 11.5373 17.1053 13.1094Z" fill="white" />
                            </svg>
                        </div>
                        <div className='text-[16px] font-light'>Visit us at our office or reach out to us online. We’re always here to help!</div>
                    </div>
                </div>
            </div>
            <ContactUsForm />
            {/* <StayInLoop /> */}
        </div>
    )
}

export default ContactUs