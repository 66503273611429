import React from 'react'
import Trending from './Trending'
import Adv from './Adv'
import Services from './Services'
import RecentProjects from './RecentProjects'
import Welcome from './Welcome'
import AboutTacit from './AboutTacit'
import Slider from './Slider'
import HowItWork from './HowItWork'
import HaveAProject from '../common/HaveAProject'
// import Testimonials from '../portfolio/Testimonials'
import TestimonialSlider from './TsetimonialSlider'
import StatsSection from './StatsSection'

const Home = () => {
    return (
        <div className='flex flex-col gap-[50px]'>
            <div className='text-white w-[85%] mx-auto gap-[50px] flex flex-col'>
                {/* <Welcome /> */}
                <AboutTacit />
                <Slider />
                {/* <Trending /> */}
                <HowItWork />
                {/* <Adv /> */}
            </div>
            {/* <Services /> */}
            {/* <div className='text-white w-[85%] mx-auto gap-[50px] flex flex-col'> */}
            {/* <RecentProjects /> */}

            {/* </div> */}
            {/* <Testimonials /> */}
            <StatsSection />
            <TestimonialSlider />
            <HaveAProject />
        </div>
    )
}

export default Home