export const fadeIn = (direction, delay) => {
  return {
    hidden: {
      y: direction === 'up' ? 40 : direction === 'down' ? -40 : 0,
      x: direction === 'left' ? 40 : direction === 'right' ? -40 : 0,
      opacity: 0
    },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        type: 'tween',
        duration: 1.4,
        delay: delay,
        ease: [0.25, 0.25, 0.25, 0.75],
      },
    },
  };
};
export const fadeOut = ({ direction = 'up', delay = 0, duration = 1.2, scale = 1, rotate = 0 }) => {
  return {
    hidden: {
      y: direction === 'up' ? 40 : direction === 'down' ? -40 : 0,
      x: direction === 'left' ? 40 : direction === 'right' ? -40 : 0,
      scale: 0.8, // Start with scaling down
      opacity: 0,
      rotate: rotate, // Optional rotation
    },
    show: {
      y: 0,
      x: 0,
      scale: scale, // Final scale (default is 1)
      opacity: 1,
      rotate: 0, // Reset rotation
      transition: {
        type: 'tween',
        duration: duration, // Make duration configurable
        delay: delay,
        ease: [0.42, 0, 0.58, 1], // Add a stronger cubic-bezier ease for smooth effect
      },
    },
  };
};
export const parallax = ({ offset = 100, duration = 1, delay = 0 }) => ({
  hidden: {
    y: offset, // Move the element based on offset value
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 20,
      duration: duration,
      delay: delay,
    },
  },
});
export const zoomEffect = ({ zoom = 0.8, delay = 0, duration = 1.2 }) => ({
  hidden: {
    scale: zoom, // Zoom out effect (shrink the element)
    opacity: 0,
  },
  show: {
    scale: 1, // Reset to full size
    opacity: 1,
    transition: {
      duration: duration,
      delay: delay,
      ease: [0.6, 0.01, -0.05, 0.9], // Add smooth easing for zoom effect
    },
  },
});
export const staggerChildren = (delay = 0.1) => ({
  show: {
    transition: {
      staggerChildren: delay, // Delay between each child animation
    },
  },
  hidden: {},
});

export const slideIn = ({ direction = 'left', delay = 0, duration = 0.8 }) => {
  return {
    hidden: {
      x: direction === 'left' ? -100 : direction === 'right' ? 100 : 0,
      opacity: 0,
    },
    show: {
      x: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
        damping: 20,
        duration: duration,
        delay: delay,
      },
    },
  };
};

export const rotateIn = ({ degrees = 180, delay = 0, duration = 1.5 }) => ({
  hidden: {
    rotate: degrees, // Start with rotation
    opacity: 0,
  },
  show: {
    rotate: 0, // End with no rotation
    opacity: 1,
    transition: {
      duration: duration,
      delay: delay,
      ease: [0.68, -0.55, 0.27, 1.55], // Dramatic rotation ease
    },
  },
});
// variants.js
export const fadeInFromBottom = (delay = 0, duration = 1.2) => {
  return {
    hidden: {
      y: 50, // Coming from the bottom
      opacity: 0, // Start hidden
    },
    show: {
      y: 0, // Move to its original position
      opacity: 1, // Fade in
      transition: {
        type: 'tween',
        duration: duration,
        delay: delay,
        ease: [0.25, 0.25, 0.25, 0.75], // Smooth easing
      },
    },
  };
};