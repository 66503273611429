import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';
import Button from '../Utils/Button';
import toast, { Toaster } from 'react-hot-toast';

const ContactUsForm = () => {
    const [loader, setLoader] = useState(false);
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            message: ''
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .max(15, 'Must be 15 characters or less')
                .required('First Name is required'),
            lastName: Yup.string()
                .max(20, 'Must be 20 characters or less')
                .required('Last Name is required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Email is required'),
            message: Yup.string()
                .min(10, 'Message must be at least 10 characters long')
                .required('Message is required'),
        }),
        onSubmit: async (values, { resetForm }) => {
            if (loader) return;
            setLoader(true)
            const fullName = `${values.firstName} ${values.lastName}`;
            await emailjs.send('service_lxe9vzb', 'template_vk4vs1p',
                {
                    to_name: 'Ankit',
                    message: values.message,
                    from_name: fullName,
                    user_email: values.email
                }
                , 'rLXOyMuYwIDbdXmTv')
                .then((response) => {
                    toast.success('Message sent successfully!')
                    resetForm();
                })
                .catch((error) => {
                    toast.error('Failed to send message. Please try again.')
                })
                .finally(() => {
                    setLoader(false);
                });
        },
    });

    return (
        <>
            <Toaster />
            <div className="min-h-screen bg-gradient-to-b from-[rgba(237,249,255,0.2)] to-[rgba(52,227,255,0.6)] w-[95%] bg-opacity-[50%] mx-auto flex justify-center items-center">
                <div className='w-[70%] mx-auto p-8 rounded-lg form_container'>
                    <h2 className="text-center text-[30px] mb-6 text-white ">Get in Touch with Us</h2>
                    <p className="text-center text-[13px] font-medium mb-8 text-white">We are here to help! Whether you have a question about our services, pricing, or anything else, feel free to send us a message. Our team will respond to you as soon as possible.</p>
                    <form onSubmit={formik.handleSubmit} className='space-y-6'>
                        <div className='flex gap-[20px] first_last_name'>
                            <div className='w-[49%] relative flex flex-col gap-[8px] first_name'>
                                <label htmlFor="firstName" className="block text-[14px] font-medium ">First Name</label>
                                <input
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    className={`mt-1 p-4 w-full rounded-md bg-[#FFFFFF] bg-opacity-[10%]  focus:outline-none focus:ring-2 focus:ring-orange-500 ${formik.touched.firstName && formik.errors.firstName ? 'ring-2 ring-red-500' : ''
                                        }`}
                                    {...formik.getFieldProps('firstName')}
                                />
                                {formik.touched.firstName && formik.errors.firstName ? (
                                    <div className="text-red-300 text-[12px] absolute bottom-[-20px]">{formik.errors.firstName}</div>
                                ) : null}
                            </div>
                            <div className='w-[49%] relative flex flex-col gap-[8px]  first_name'>
                                <label htmlFor="lastName" className="block text-[14px] font-medium ">Last Name</label>
                                <input
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    className={`mt-1 p-4 w-full rounded-md bg-[#FFFFFF] bg-opacity-[10%]  focus:outline-none focus:ring-2 focus:ring-orange-500 ${formik.touched.lastName && formik.errors.lastName ? 'ring-2 ring-red-500' : ''
                                        }`}
                                    {...formik.getFieldProps('lastName')}
                                />
                                {formik.touched.lastName && formik.errors.lastName ? (
                                    <div className="text-red-300 text-[12px] absolute bottom-[-20px]">{formik.errors.lastName}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className='relative flex flex-col gap-[8px]'>
                            <label htmlFor="email" className="block text-[14px] font-medium ">Email Address</label>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                className={`mt-1 p-4 w-full rounded-md bg-[#FFFFFF] bg-opacity-[10%] focus:outline-none focus:ring-2 focus:ring-orange-500 ${formik.touched.email && formik.errors.email ? 'ring-2 ring-red-500' : ''
                                    }`}
                                {...formik.getFieldProps('email')}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="text-red-300 text-[12px] absolute bottom-[-20px]">{formik.errors.email}</div>
                            ) : null}
                        </div>
                        <div className='relative flex flex-col gap-[8px]'>
                            <label htmlFor="message" className="block text-[14px] font-medium ">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                className={`mt-1 p-4 w-full h-[200px] rounded-md bg-[#FFFFFF] bg-opacity-[10%] focus:outline-none focus:ring-2 focus:ring-orange-500 ${formik.touched.message && formik.errors.message ? 'ring-2 ring-red-500' : ''
                                    }`}
                                {...formik.getFieldProps('message')}
                            />
                            {formik.touched.message && formik.errors.message ? (
                                <div className="text-red-300 text-[12px] absolute bottom-[-20px] ">{formik.errors.message}</div>
                            ) : null}
                        </div>
                        <div className='flex justify-end'>
                            <Button radius='5px' paddingX='25px' paddingY='11px' content='Get in touch' bg='#FA9021' handler={formik.handleSubmit} loader={loader} />
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ContactUsForm;
