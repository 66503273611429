import React from 'react'
import imag1 from '../../images/services/image 21.png'
import imag2 from '../../images/services/image_20.png'
import imag3 from '../../images/services/image_21.png'
import imag4 from '../../images/services/image_22.png'
import imag5 from '../../images/services/image_23.png'
import imag6 from '../../images/services/image_24.png'
import imag7 from '../../images/services/image_25.png'
import imag8 from '../../images/services/image_26.png'
import Button from '../Utils/Button'
import HaveAProject from '../common/HaveAProject'
import './ServicesPage.css'
import { motion } from 'framer-motion';
import { fadeInFromBottom, slideIn } from '../Utils/Variants'
import { Link } from 'react-router-dom'
const servicesData = [
  {
    title: "Game Development",
    description: "Experience immersive worlds with FACT's game development expertise. We craft captivating games for multiple platforms, delivering unique experiences through art and innovative gameplay.",
    buttonText: "Learn More",
    image: imag2
  },
  {
    title: "Web Development",
    description: "Unlock your online potential with FACT's web development expertise. We provide user-friendly websites, combining creativity and innovative technologies to deliver an exceptional online experience.",
    buttonText: "Learn More",
    image: imag3
  },
  {
    title: "App Development",
    description: "Unlock your app potential with FACT's development expertise. We develop high-performing, user-friendly applications, combining creativity and technical skills for seamless experiences.",
    buttonText: "Learn More",
    image: imag4
  },
  {
    title: "Digital Marketing",
    description: "Boost your brand visibility with FACT's digital marketing expertise. We strategically plan campaigns to increase engagement and conversions, helping your online presence grow.",
    buttonText: "Learn More",
    image: imag1
  },
  {
    title: "UI/UX Designing",
    description: "Elevate user experience with FACT's UI/UX design solutions. We create intuitive, user-centric designs that seamlessly combine aesthetics and functionality.",
    buttonText: "Learn More",
    image: imag5
  },
  {
    title: "3D Designing",
    description: "Explore limitless possibilities with FACT's 3D designing services. We create stunning, realistic models for various industries including entertainment and architecture.",
    buttonText: "Learn More",
    image: imag6
  },
  {
    title: "AR-VR Development",
    description: "Revolutionize your future with FACT's AR/VR development expertise. We create cutting-edge immersive experiences to transform virtual reality into a new dimension.",
    buttonText: "Learn More",
    image: imag7
  },
  {
    title: "Architectural Designing",
    description: "Design innovative spaces with FACT's architectural expertise. We bring your vision to life, combining aesthetics and functionality in every space we design.",
    buttonText: "Learn More",
    image: imag8
  }
];
const ServicesPage = () => {
  return (
    <div className='w-full flex flex-col items-center justify-center py-[50px] gap-[20px] overflow-hidden'>
      <div className='services_page_heading_div'>
        <h1 className='font-extrabold text-[30px] max-[500px]:text-[25px] max-[500px]:font-bold'>Featured works</h1>
        <p className='text-[15px] text-center max-[500px]:text-[13px]'>Dramatically supply transparent backward deliverables before award comp internal or "organic" sources.</p>
      </div>
      <div className='w-[90%] mx-auto flex flex-col gap-[20px]'>
        {servicesData.map((item, index) => (
          <div className='service_image_div' key={index}>
            {index % 2 === 0 ?
              <motion.div
                variants={slideIn({ direction: 'right', delay: 0.1, duration: 0.8 })}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.7 }} className='services_page_left'>
                <div className='w-[521px] h-[410px]'><img className='w-full h-full' src={item.image} /></div>
                <div className='w-[47%] flex-col flex items-center justify-center gap-[10px]'>
                  <h1 className='font-extrabold text-[44px] text-center uppercase'>{item.title}</h1>
                  <p className='font-medium tex-[24px] text-center'>{item.description}</p>
                  <Link to='/contactus'><Button radius='50px' paddingX='25px' paddingY='11px' content='More Details' icon={true} /></Link>
                </div>
              </motion.div>
              :
              <motion.div
                variants={slideIn({ direction: 'left', delay: 0.1, duration: 0.8 })}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.7 }} className='services_page_left'>
                <div className='w-[47%] flex-col flex items-center justify-center gap-[10px]'>
                  <h1 className='font-extrabold text-[44px] text-center uppercase'>{item.title}</h1>
                  <p className='font-medium tex-[24px] text-center'>{item.description}</p>
                  <Link to='/contactus'><Button radius='50px' paddingX='25px' paddingY='11px' content='More Details' icon={true} /></Link>
                </div>
                <div className='w-[47%] max-w-[521px] h-[410px]'><img className='w-full h-full' src={item.image} /></div>
              </motion.div>
            }
          </div>
        ))}
        {servicesData.map((item, index) => (
          <motion.div
            variants={fadeInFromBottom()}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.7 }} className='service_image_div_mobile' key={index}>
            <div className='services_page_left'>
              <div className='w-[521px] h-[410px] service_image'><img className='w-full h-full ' src={item.image} /></div>
              <div className='w-full flex-col flex items-center justify-center gap-[10px]'>
                <h1 className='font-extrabold text-[44px] text-center uppercase service_image_title'>{item.title}</h1>
                <p className='font-medium text-[24px] text-center service_image_des'>{item.description}</p>
                <Link to='/contactus'><Button radius='50px' paddingX='25px' paddingY='11px' content='More Details' icon={true} /></Link>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
      <HaveAProject />
    </div>
  )
}

export default ServicesPage