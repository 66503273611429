import React, { useEffect, useState } from "react";
import image from '../../images/portfolio/testimonial/Ellipse 175.jpg';
import image3 from '../../images/portfolio/testimonial/Ellipse 239.png';
import image4 from '../../images/portfolio/testimonial/alex-mccarthy-RGKdWJOUFH0-unsplash.jpg';
import image5 from '../../images/portfolio/testimonial/brooke-cagle-wKOKidNT14w-unsplash.jpg';
import './Testimonial.css'
const testimonials = [
    {
        name: "Rajesh Kumar",
        location: "Mumbai, India",
        rating: 4.8,
        image: image,
        review: "The service was excellent, and the team was professional throughout. I highly recommend it to anyone looking for top-notch service. They exceeded my expectations.",
    },
    {
        name: "Samantha Lee",
        location: "New York, USA",
        rating: 4.9,
        image: '',
        review: "Incredible attention to detail and customer service. I am beyond impressed with how smoothly everything went. I'll definitely use them again!"
    },
    {
        name: "Ananya Sharma",
        location: "Delhi, India",
        rating: 4.7,
        image: image4,
        review: "I am really happy with the results! The support team was incredibly responsive, and they were always available to help. I will definitely use this service again in the future.",
    },
    {
        name: "Amit Patel",
        location: "Ahmedabad, India",
        rating: 4.6,
        image: image3,
        review: "The entire process was seamless. From start to finish, I was kept informed, and the final output was exactly what I had hoped for. A great experience overall.",
    },
    {
        name: "Pooja Mehta",
        location: "Bengaluru, India",
        rating: 5.0,
        image: '',
        review: "Absolutely brilliant! The quality of the service was outstanding, and the team was very friendly and professional. I will definitely recommend it to others.",
    },
    {
        name: "Rohit Verma",
        location: "Chennai, India",
        rating: 4.9,
        image: '',
        review: "I had a very smooth and hassle-free experience. The team was extremely skilled and provided excellent solutions to my requirements. A job well done!",
    },
    {
        name: "Ahmed Ali",
        location: "Dubai, UAE",
        rating: 4.7,
        image: image5,
        review: "Outstanding service from start to finish. They really understood what I needed and delivered a solution tailored perfectly to my requirements."
    },
    {
        name: "Neha Iyer",
        location: "Hyderabad, India",
        rating: 4.7,
        image: '',
        review: "Great service! They understood my needs perfectly and delivered more than I expected. The attention to detail and customer care was impressive.",
    },
];

const TestimonialSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    // Automatically change the slide every 5 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000); // Change the slide every 5 seconds
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="w-full mx-auto flex flex-col gap-[20px]">
            <h2 className="text-center text-[54px] font-bold max-[720px]:text-[34px]">TESTIMONIALS</h2>
            <div className="text-center text-[22px] w-[60%] mx-auto max-[720px]:text-[14px] max-[720px]:w-[92%]">
                Dramatically supply transparent backward deliverables before award comp internal or "organic" sources.
            </div>
            <div className="border-b w-[90%] mx-auto"></div>
            <div className="flex flex-col items-center gap-[20px]">
                <div className="flex items-center justify-center">
                    {[...Array(Math.floor(testimonials[currentIndex].rating))].map((_, i) => (
                        <span key={i} className="text-[#F2C94C] text-[24px] max-[720px]:text-lg">★</span>
                    ))}
                    {testimonials[currentIndex].rating % 1 !== 0 && (
                        <span className="text-[#F2C94C] text-[24px] max-[720px]:text-lg">★</span>
                    )}
                    <p className="px-[4px] max-[720px]:text-[18px] text-[25px]  font-bold">{testimonials[currentIndex].rating}</p>
                </div>
                <p className="text-[25px] h-[150px] max-[780px]:h-auto font-bold text-center w-[80%] max-[720px]:w-[94%] max-[720px]:text-[18px]">{testimonials[currentIndex].review}</p>
                <div className="flex items-center justify-center gap-[20px]">
                    {testimonials[currentIndex].image
                        ?
                        <img
                            src={testimonials[currentIndex].image}
                            alt={testimonials[currentIndex].name}
                            className="w-[125px] max-[720px]:w-[100px] h-[125px] max-[720px]:h-[100px] rounded-full object-cover"
                        />
                        : <div className="bg-blue-100 w-[125px] max-[720px]:w-[100px] h-[125px] max-[720px]:h-[100px] flex items-center justify-center rounded-full"><svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg></div>
                    }
                    <div>
                        <p className="text-[26px] font-medium max-[720px]:text-[18px]">{testimonials[currentIndex].name}</p>
                        <p className="max-[720px]:text-[12px]">{testimonials[currentIndex].location}</p>
                    </div>
                </div>
                <div className="flex space-x-2">
                    {testimonials.map((_, index) => (
                        <span
                            key={index}
                            onClick={() => setCurrentIndex(index)}
                            className={`cursor-pointer w-[12px] max-[720px]:w-[10px] h-[12px] max-[720px]:h-[10px] rounded-full ${index === currentIndex ? "bg-[#DC7000]" : "bg-white"
                                }`}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
export default TestimonialSlider;
