import React, { useEffect, useState } from 'react'
import Button from '../Utils/Button'
import { Link, useLocation } from 'react-router-dom'
import bgImage2 from '../../images/hero.png'
import DynamicTextBanner from './DynamicTextBanner'
import HeaderForMobile from './HeaderForMobile'
import './Header.css'
import logo from '../../images/logo.png'

const Header = () => {
    const location = useLocation();
    const [headerForMobile, setHeaderForMobile] = useState(false)
    const getHeadingForPage = () => {
        switch (location.pathname) {
            case '/':
                return 'HOME';
            case '/services':
                return 'SERVICES';
            case '/blog':
                return 'BLOG';
            case '/contactus':
                return 'CONTACT';
            default:
                return '';
        }
    };
    useEffect(() => {
        setHeaderForMobile(false)
    }, [location.pathname])
    return (
        <>{location.pathname === '/'
            ?
            <div className='text-white h-screen bg-cover relative flex flex-col justify-between' style={{ backgroundColor: '#070B1D' }} >
                <div className=' flex justify-between w-full p-[20px] z-[2]'>
                    <div className='w-1/2 z-[1]'>
                        <Link to='/'>
                            <h1 className='w-[75px] h-[73px] max-[770px]:w-[45px] max-[770px]:h-[43px] z-[1]'>
                                <img className='w-full h-full z-[1]' src={logo} />
                                {/* <svg width="100%" height="100%" viewBox="0 0 75 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27.7458 14.3064L60.6938 0L26.0117 34.8988L27.7458 14.3064Z" fill="#0AC4FF" />
                                    <path d="M73.2658 30.5639L74.9999 14.3066H50.9392L25.1444 40.1014L24.2773 58.3095C25.7326 67.0844 28.8675 69.9976 37.9334 72.1824H52.2398L53.5403 56.1419H46.6039C43.6118 55.5436 42.2055 54.9023 41.6184 51.5899L43.786 30.5639H73.2658Z" fill="white" />
                                    <path d="M0 30.5639L1.51734 14.5234H25.1445L23.6272 30.5639H0Z" fill="white" />
                                </svg> */}
                            </h1>
                        </Link>
                    </div>
                    <div className='header_buttons '>
                        <Link to='/'>Home</Link>
                        <Link to='/services'>Services</Link>
                        {/* <Link to='/portfolio'>Portfolio</Link> */}
                        {/* <Link to='/blog'>Blog</Link> */}
                        <Link to='/contactus'><Button radius='5px' paddingX='25px' paddingY='11px' content='Contact US' /></Link>
                    </div>
                    <div className='hamBurg' onClick={() => setHeaderForMobile(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-align-justify"><line x1="21" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="21" y1="18" x2="3" y2="18"></line></svg>
                    </div>
                    {headerForMobile && <HeaderForMobile setHeaderForMobile={setHeaderForMobile} />}
                </div>
                <DynamicTextBanner />
                <div className='flex justify-between p-[20px] z-[1]'>
                    <div className='w-[45%] flex justify-between'>
                        <p className='uppercase text-[12px]'>We Are In Top</p>
                        <li className='text-[12px] custom-dot max-[720px]:hidden'>Everyting you need to get the attention of your audience.</li>
                    </div>
                    <p className='text-[12px]'>#Tacit</p>
                </div>
                <div className='absolute z-0 w-full h-screen'>
                    <svg width="100%" height="100%" viewBox="0 0 1032 612" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1030.25 12.4592C906.355 -18.0625 618.436 -1.34007 457.917 309.723C297.398 620.785 -81.7445 630.863 -251.251 597.019" stroke="#0A0F26" stroke-width="2.25" stroke-linecap="round" />
                    </svg>
                </div>
                <div className='absolute z-0 w-full h-screen'>
                    <svg width="100%" height="100%" viewBox="0 0 965 810" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M963.658 -101.386C836.146 -96.7047 563.893 -1.54524 494.985 341.643C426.076 684.831 64.283 798.657 -108 812.671" stroke="#0A0F26" stroke-width="2.25" stroke-linecap="round" />
                    </svg>
                </div>
            </div>
            :
            <div className='text-white h-screen bg-cover relative' style={{ backgroundImage: `url('${bgImage2}')` }} >
                <div className='flex justify-between w-full p-[20px]'>
                    <div className='w-1/2'>
                        <Link to='/'><h1 className='w-[75px] h-[73px] max-[720px]:w-[45px] max-[720px]:h-[43px]'>
                            <img className='w-full h-full' src={logo} />
                            {/* <svg width="100%" height="100%" viewBox="0 0 75 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.7458 14.3064L60.6938 0L26.0117 34.8988L27.7458 14.3064Z" fill="#0AC4FF" />
                                <path d="M73.2658 30.5639L74.9999 14.3066H50.9392L25.1444 40.1014L24.2773 58.3095C25.7326 67.0844 28.8675 69.9976 37.9334 72.1824H52.2398L53.5403 56.1419H46.6039C43.6118 55.5436 42.2055 54.9023 41.6184 51.5899L43.786 30.5639H73.2658Z" fill="white" />
                                <path d="M0 30.5639L1.51734 14.5234H25.1445L23.6272 30.5639H0Z" fill="white" />
                            </svg> */}
                        </h1></Link>
                    </div>
                    <div className='header_buttons '>
                        <Link to='/'>Home</Link>
                        <Link to='/services'>Services</Link>
                        {/* <Link to='/portfolio'>Portfolio</Link> */}
                        {/* <Link to='/blog'>Blog</Link> */}
                        <Link to='/contactus'><Button radius='5px' paddingX='25px' paddingY='11px' content='Contact US' /></Link>
                    </div>
                    <div className='hamBurg' onClick={() => setHeaderForMobile(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" class="feather feather-align-justify"><line x1="21" y1="10" x2="3" y2="10"></line><line x1="21" y1="6" x2="3" y2="6"></line><line x1="21" y1="14" x2="3" y2="14"></line><line x1="21" y1="18" x2="3" y2="18"></line></svg>
                    </div>
                    {headerForMobile && <HeaderForMobile setHeaderForMobile={setHeaderForMobile} />}
                </div>
                <div className='w-full h-full font-extrabold text-[55px] max-[720px]:text-[35px] absolute top-[40%] pl-[20px]'>{getHeadingForPage()}</div>
            </div>
        }
        </>
    )
}

export default Header