import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Button from '../Utils/Button';

const HeaderForMobile = ({ setHeaderForMobile }) => {
    const [forClose, setForClose] = useState(true);
    return (
        <div className='fixed w-full h-full bg-black bg-opacity-50 top-0 left-0 right-0 bottom-0 z-[2]'>
            <div className={`w-[36%] min-w-[300px] h-full text-white bg-gradient-to-b from-[#050D36] z-[5] to-[#050D36] flex flex-col gap-[15px] p-[10px] ${forClose ? 'animate-navBarMenuOpen' : 'animate-navBarMenuClose'}`}>
                <div className='p-[10px] flex justify-end' onClick={() => { setForClose(false); setTimeout(() => { setHeaderForMobile(false); }, 500); }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </div>
                <Link to='/'>Home</Link>
                <Link to='/services'>Services</Link>
                <Link to='/contactus'><Button radius='5px' paddingX='25px' paddingY='11px' content='Contact US' /></Link>
            </div>
        </div>
    )
}

export default HeaderForMobile